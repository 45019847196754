import Splide from '@splidejs/splide';
import $ from 'jquery';

document.addEventListener("turbolinks:load", () => {
  let fields = [$('#rent_pickup_location'), $('#rent_pickup_time'), $('#rent_return_location'), $('#rent_return_time')];
  let is_filled = [false];
  initializeNewRent();
  initializeVehicleSelect();

  function queryVehicle(){
    $.ajax({
      url: '/rent_vehicles/guest_query', 
      data: {
        rent: {
          pickup_time: fields[1].val(),
          return_time: fields[3].val(),
        }
      },
      method: "POST",
      beforeSend: function(xhr){ xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
      success: function(data, _status, _xhr){
        $('#indexVehicles').replaceWith(data);
        let vehicles_list = $('.vehicles-list');
        $.each(vehicles_list, function(id, vehicle){ 
          new Splide(vehicle, {
            type: 'slide',
            perPage: 1,
            perMove: 1,
            isNavigation: true,
            cover: true,
            height: '40vh',
            width: '100%',
            arrows: true,
            start: 0,
            rewind: true,
          } ).mount();
        });
        initializeVehicleSelect();
      },
      error: function(xhr, status, e){
        console.log(xhr, status, e);
      }
    });
  }

  function vehicleAvailability(){
    $.ajax({
      url: '/rent_vehicles/guest_availability', 
      data: {
        vehicle_filter: {
          pickup_time: fields[1].val(),
          return_time: fields[3].val(),
          vehicle_id: $('#rent_vehicle_id').val()
        }
      },
      method: "POST",
      beforeSend: function(xhr){ xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
      success: function(data, _status, _xhr){
        if(data["available"]){
          $('#rent-form-submit').closest('form').trigger('submit');
        }else{
          $('#rent_vehicle_id').val('')
          queryVehicle();
          window.scrollTo({
            top: $('#indexVehicles').offset().top,
            left: 0,
            behavior: 'smooth'
          })
        }
      },
      error: function(xhr, status, e){
        console.log(xhr, status, e);
      }
    });
  }

  let correctInfo = false

  function initializeNewRent(){
    $('#new_rent .form-control').on('change', function(e){
      is_filled = fields.map(function(field, i){ return $(field).val() !== ''; });

      var pickup_date = new Date(fields[1].val());
      var return_date = new Date(fields[3].val());

      var timeDiff = Math.abs(return_date.getTime() - pickup_date.getTime());
      var diffInHours = timeDiff / (1000 * 3600);

      if(($.inArray(false, is_filled) == -1)){
        if(diffInHours < 24) {
          correctInfo = false
          $(fields[1]).addClass('required-field');
          $(fields[3]).addClass('required-field');
          alert('Minimalus rezervacijos laikas yra viena para');
        } else if(fields[1].val() > fields[3].val()){
          correctInfo = false
          $(fields[1]).addClass('required-field');
          $(fields[3]).addClass('required-field');
          alert('Grąžinimo laikas negali būti ankstesnis nei paėmimo laikas');
        } else if (fields[1].val() == fields[3].val()) {
          correctInfo = false
          $(fields[1]).addClass('required-field');
          $(fields[3]).addClass('required-field');
          alert('Grąžinimo laikas negali būti toks pat kaip paėmimo laikas');
        }else if($('#rent_vehicle_id').val() === ''){
          window.scrollTo({
            top: $('#indexVehicles').offset().top,
            left: 0,
            behavior: 'smooth'
          });
          queryVehicle();
          $.each(fields, function(i, field){ $(field).removeClass('required-field')});
          $.each(window.date_rent, function(i, date_rent){ date_rent.close()}); 
          correctInfo = true
        }else{
          vehicleAvailability();
          $.each(fields, function(i, field){ $(field).removeClass('required-field')});
          $.each(window.date_rent, function(i, date_rent){ date_rent.close()});
        }
      }else{
        $.each(fields, function(i, field){ $(field).addClass('required-field')});
      }
    });
  }

  function initializeVehicleSelect(){
    $('#indexVehicles .vehicle-selector').on('click', function(e){
      e.preventDefault();
      var vehicle_id = $(this).data('id');
      $('#rent_vehicle_id').val(vehicle_id);
      // is_filled = fields.map(function(field, i){ return $(field).val() !== ''; });
      is_filled = fields.map(function(field, i){ return correctInfo; });
  
      if($.inArray(false, is_filled) != -1){
        $.each(fields, function(i, field){ $(field).addClass('required-field')});
        window.scrollTo({
          top: $('#new_rent').offset().top,
          left: 0,
          behavior: 'smooth'
        });
      }else{
        $('#rent-form-submit').closest('form').trigger('submit');
      }
    });
  }
});